import React, { useState } from 'react';
import './Footer.css';
import GoogleMapsLink from './GoogleMapsLink';
import { FaLocationDot } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi";
import { IoLogoWechat } from "react-icons/io5";
import footerLogo from './images/logo.png';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
    const shopAddress = '4501 Stelton Rd, South Plainfield, NJ 07080';
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMenu = () => setClick(false);
    return (
        <>
            <div className="Footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5 col-12 ft-1">
                            <Link to='/' className="footer-logo" onClick={closeMenu}>
                                <img id='logo-icon' src={footerLogo} />
                            </Link>
                            <h3>CommonWealth Motor</h3>
                            <p>Located in New Jersey and proudly serving the New York, Pennsylvania, Boston, and Delaware areas. At Commonwealth Motor, we bring fine cars to discerning drivers. For more information, visit our contact page. Follow us on social media for the latest updates and exclusive offers.</p>
                            <SocialIcon url="https://www.instagram.com/commonwealthmotornj/" bgColor="black" style={{ height: 50, width: 50 }} className='social-icons' target="_blank"/>
                            <SocialIcon url="https://www.xiaohongshu.com/user/profile/61bea4c8000000001000700e" style={{ height: 50, width: 50 }} className='social-icons' target="_blank"/>
                        </div>
                        <div className="col-md-6 col-lg-3 col-12 ft-2">
                            <h5>Quick Links</h5>
                            <ul>
                                <li className="nav-item">
                                    <a className="" href="/inventory">Inventory</a>
                                </li>
                                <li className="nav-item">
                                    <a className="" href="/about">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="" href="/contact">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12 ft-3">
                            <h5>Reach Us Here</h5>
                            <p><FiPhoneCall /> +1 302-480-3988</p>
                            <p><IoLogoWechat /> CommonWealth Motor</p>
                            <p><FaLocationDot /> <GoogleMapsLink address={shopAddress} /></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Last-footer'>
                <p>© 2024 Commonwealth Motor. All Rights Reserved.</p>
            </div>
        </>
    )
}

export default Footer