import React from 'react';
import Intro from '../Intro.js';
import ShowRoom from '../ShowRoom.js'
import '../ShowRoom.css'
import './home.css'

function home(){
    return(
        <>
            <Intro />
            <div className="showroom-container">
                <ShowRoom />
            </div>
        </>
    );
}

export default home