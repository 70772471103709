import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ImageCarousel from '../ImageCarousel';
import ContactBox from '../contactBox';
import './carDetails.css';

const CarDetails = () => {
  const { id } = useParams();
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(`${API_URL}/cars/${id}/`)
      .then(response => {
        setCar(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the car details!', error);
        setLoading(false);
      });
  }, [id, API_URL]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!car) {
    return <div>No car details available</div>;
  }

  const carVin = car.vin;
  const images = [car.profile_pic, ...car.images.map(img => img.images)];
  const carTitle = `${car.year} ${car.brand} ${car.model}`;
  const defaultSubject = `Interested in ${carTitle}`;
  const defaultMessage = `I would like more information about the ${carTitle}.`;

  return (
    <div className="car-details-page">
      <h1 className="car-title">{carTitle}</h1>
      <div className="car-details-content">
        <div className="left-section">
          <ImageCarousel images={images} />
          <div className="car-detail-title">
              <span className="vehicle-detail-title">Vehicle Detail</span>
              <span className="carfax-link">
                <a 
                  href={`http://www.carfax.com/VehicleHistory/p/Report.cfx?partner=DVW_1&vin=${carVin}`}
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <img 
                    src="http://www.carfaxonline.com/assets/subscriber/showmethecarfax.jpg" 
                    width="250" 
                    height="100" 
                    border="0" 
                    alt="Show me the Carfax" 
                  />
                </a>
              </span>
            </div>
          <div className="car-details-grid">
            <div className="car-detail">
              <span className="car-detail-label">Model</span>
              <span className="car-detail-value">{car.model}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label">Transmission</span>
              <span className="car-detail-value">{car.transmission}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label">MPG</span>
              <span className="car-detail-value">{car.mpg}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label">Engine</span>
              <span className="car-detail-value">{car.engine}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label">Mileage</span>
              <span className="car-detail-value">{car.mileage}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label">Exterior Color</span>
              <span className="car-detail-value">{car.exterior_color}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label">Body Style</span>
              <span className="car-detail-value">{car.body_style}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label">Interior Color</span>
              <span className="car-detail-value">{car.interior_color}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label">VIN</span>
              <span className="car-detail-value">{carVin}</span>
            </div>
            <div className="car-detail">
              <span className="car-detail-label">Price</span>
              <span className="car-detail-value">${car.price.toLocaleString()}</span>
            </div>
          </div>
        </div>
        <div className="right-section">
          <ContactBox 
            defaultSubject={defaultSubject} 
            defaultMessage={defaultMessage}
          />
        </div>
      </div>
    </div>
  );
}

export default CarDetails;
