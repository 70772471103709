import React from 'react'
import frontpage from './images/frontpage.jpg'
import './Intro.css'


function Intro(){
    return(
        <div className='intro-container'>
            <img id='intro' src={frontpage}  alt="Front Page"/>
        </div>
        
    );
}

export default Intro