import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaGripLinesVertical } from "react-icons/fa6";
import './ShowRoom.css';

const ShowRoom = () => {
    const [cars, setCars] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchCars();
    }, []);
    
    const fetchCars = async () => {
        try {
            const response = await axios.get(`${API_URL}/cars/`);
            const allCars = response.data;
            const favoriteCars = allCars.filter(car => car.favorite);
            const randomCars = favoriteCars.sort(() => 0.5 - Math.random()).slice(0, 6);
            setCars(randomCars);
        } catch (error) {
            console.error("Error fetching cars:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCardClick = (id) => {
        navigate(`/car/${id}`);
    };

    const handleButtonClick = () => {
        navigate('/inventory'); 
    };

    const truncate = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    return (
        <div className='showroom-container'>
            <div className='showroom-title'>
                <h1>Check Out Our Inventory</h1>
            </div>
            <div className="product-grid">
                <div className="grid">
                    {loading && <div>Loading...</div>}
                    {!loading && cars.length === 0 && <div>No cars available</div>}
                    {!loading && cars.length > 0 && cars.map((car) => (
                        <div className="productCard" 
                            key={car.id}
                            onClick={() => handleCardClick(car.id)}
                            style={{ cursor: 'pointer' }}
                        >
                            <img className="Car-Profile-Pic" src={car.profile_pic} alt={`${car.brand} ${car.model}`} />
                            <div className="card-body">
                                <div className='icon-container'>
                                    <FaGripLinesVertical className="icon-style"/>
                                </div>
                                <div className='title-container'>
                                    <h5 className="card-title">{car.year} {car.brand} {car.model}</h5>
                                    <p className="card-price">${car.price}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <button>
                <span class="button_top" onClick={handleButtonClick}> Our Inventory
                </span>
            </button>
        </div>
    );
};

export default ShowRoom;
