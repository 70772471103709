import emailjs from "emailjs-com"
import React, { useRef } from 'react'
import './feedbackBox.css'
import logo from './images/logo.png'

export const FeedbackBox = () => {
    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
      .sendForm('service_h3c12et', 'template_1cwab5p', form.current, 'Jxp06y9U5VmEzGA9m')
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
        e.target.reset(); 
    };
  
    return (
        <div className="feedbackContainer">
            <form ref={form} className="feedback-form" onSubmit={sendEmail}>
            <div className="feedback-header">
                <img id="logo-icon" src={logo} alt="Logo" />
                <h2>Feedback Form</h2>
            </div>
            <div>
            <label>First Name:</label>
            <input
                type="text"
                name="firstName"
            />
            </div>
            <div>
            <label>Last Name:</label>
            <input
                type="text"
                name="lastName"
            />
            </div>
            <div>
            <label>Email:</label>
            <input
                type="email"
                name="email"
            />
            </div>
            <div>
            <label>Phone:</label>
            <input
                type="tel"
                name="phone"
            />
            </div>
            <div>
            <label>Subject:</label>
            <input
                type="text"
                name="subject"
            />
            </div>
            <div>
            <label>Message:</label>
            <textarea
                name="message"
            ></textarea>
            </div>
            <button type="submit">Send Feedback</button>
            </form>
        </div>
        
    );
  };
  export default FeedbackBox